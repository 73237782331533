<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <div class="d-flex align-center">
        <h3 class="text-h5 mb-2 mr-3">Все локализация</h3>
        <v-select
            v-model="localeGroupId"
            :items="localeGroups"
            item-text="valueRu"
            item-value="id"
            label="Group"
            outlined
            hide-details="auto"
        ></v-select>
      </div>
      <v-btn
          v-if="can($permissions['LOCALIZATION.UPSERT'])"
          color="primary"
          @click="openModal"
      >
        Добавить
      </v-btn>
    </div>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th style="max-width: 50px" class="text-left">
              №
            </th>
            <th class="text-left">
              Keyword
            </th>
            <th>Value Ru</th>
            <th>Value Uz</th>
            <th>Value UzL</th>
            <th>Value En</th>
            <th v-if="ableToAction" class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              class="faq-tr"
              v-for="(item, itemIndex) in localeList"
              :key="itemIndex"
          >
            <td>{{ itemIndex + 1 }}</td>
            <td>{{ item.keyword }}</td>
            <td>{{ item.valueRu }}</td>
            <td>{{ item.valueUz }}</td>
            <td>{{ item.valueUzl }}</td>
            <td>{{ item.valueEn }}</td>
            <td v-if="ableToAction">
              <v-row justify="center">

                <div style="min-width: 60px" class="d-flex justify-center mr-2">
                  <v-icon v-if="can($permissions['LOCALIZATION.MOVEUP'])" @click="moveUp(item.id)"
                          class="cursor-pointer move-icon-up">mdi-chevron-up
                  </v-icon>
                  <v-icon v-if="can($permissions['LOCALIZATION.MOVEDOWN'])" @click="moveDown(item.id)"
                          class="cursor-pointer move-icon-down">mdi-chevron-down
                  </v-icon>
                </div>
                <v-icon
                    v-if="can($permissions['LOCALIZATION.UPSERT'])"
                    @click="getLocale(item.id)"
                    class="cursor-pointer mr-2">mdi-pencil
                </v-icon>
                <v-icon
                    v-if="can($permissions['LOCALIZATION.TOGGLE'])"
                    @click="toggle(item.id)"
                    class="cursor-pointer">
                  {{ item.isDeleted ? 'mdi-toggle-switch-off-outline' : 'mdi-toggle-switch-outline' }}
                </v-icon>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <!--  Modals  -->
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span v-if="update" class="headline">Изменить локазицию</span>
          <span v-else class="headline">Добавить локазицию</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Keyword"
                    outlined
                    hide-details="auto"
                    v-model="locale.keyword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                    v-model="locale.groupId"
                    :items="localeGroups"
                    item-text="valueRu"
                    hide-details="auto"
                    item-value="id"
                    label="Group"
                    outlined
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    outlined
                    hide-details="auto"
                    label="Value Ru"
                    v-model="locale.valueRu"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    outlined
                    hide-details="auto"
                    label="Value Uz"
                    v-model="locale.valueUz"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    outlined
                    hide-details="auto"
                    label="Value UzL"
                    v-model="locale.valueUzl"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    outlined
                    hide-details="auto"
                    label="Value En"
                    v-model="locale.valueEn"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveLocale"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      localeList: [],
      localeGroups: [],
      localeGroupId: 1,
      locale: {
        id: 0,
        keyword: '',
        valueRu: '',
        valueEn: '',
        valueUz: '',
        valueUzl: '',
      },
      dialog: false,
      update: false
    }
  },
  computed: {
    ableToAction() {
      return this.can(this.$permissions['LOCALIZATION.UPSERT']) ||
          this.can(this.$permissions['LOCALIZATION.TOGGLE']) ||
          this.can(this.$permissions['LOCALIZATION.MOVEUP']) ||
          this.can(this.$permissions['LOCALIZATION.MOVEDOWN'])
    }
  },
  methods: {
    async getList() {
      this.showLoader();
      try {
        const res = await this.$http.get('/api-localization/getGroupItems', {params: {groupId: this.localeGroupId}});
        this.localeList = res.result;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async getGroupList() {
      this.showLoader();
      try {
        const res = await this.$http.get('/api-localization/getGroups');
        this.localeGroups = res.result;
        if (this.localeGroups && this.localeGroups.length > 0) {
          this.localeGroupId = this.localeGroups[0].id;
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async getLocale(id) {
      this.update = true;
      try {
        const res = await this.$http.get(`/api-localization/getGroupItem?itemId=${id}`);
        if (res.success) {
          this.locale = res.result;
          this.locale.groupId = this.localeGroupId;
          this.dialog = true;
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async saveLocale() {
      try {
        const res = await this.$http.post('/api-localization/upsertGroupItem', this.locale);
        if (res.success) {
          this.dialog = false;
          this.localeList = res.result;
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async toggle(id) {
      try {
        const res = await this.$http.get(`/api-localization/toggleGroupItemActivation?itemId=${id}`);
        if (res.success) {
          this.localeList = res.result;
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async moveUp(id) {
      try {
        const res = await this.$http.get(`/api-localization/moveGroupItemUp?itemId=${id}`);
        if (res) {
          this.localeList = res.result;
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async moveDown(id) {
      try {
        const res = await this.$http.get(`/api-localization/moveGroupItemDown?itemId=${id}`);
        if (res) {
          this.localeList = res.result;
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    openModal() {
      this.resetForm();
      this.update = false;
      this.dialog = true;
    },
    resetForm() {
      this.locale = {
        id: 0,
        keyword: '',
        valueRu: '',
        valueEn: '',
        valueUz: '',
        valueUzl: '',
      }
    }
  },
  watch: {
    'localeGroupId': function () {
      this.getList();
    }
  },
  async created() {
    this.getGroupList().then(x=> {this.getList()});
  }
}
</script>

<style scoped>
.faq-tr:first-child .move-icon-up {
  display: none;
}

.faq-tr:last-child .move-icon-down {
  display: none;
}
</style>